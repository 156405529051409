import { Box } from '@mentimeter/ragnar-ui';
import { HomeUserActions } from './user-actions/home-user-actions';

export function SettingsHeader() {
  return (
    <Box
      width="100%"
      extend={() => ({
        '@media print': {
          display: 'none',
        },
      })}
    >
      <Box
        width="100%"
        position="relative"
        color="onPrimary"
        alignItems="center"
        flexDirection="row"
        justifyContent="flex-end"
      >
        <HomeUserActions />
      </Box>
    </Box>
  );
}
