import { Box, type BoxT } from '@mentimeter/ragnar-ui';
import { ScrollContainer } from './components';

type LayoutMainProps = BoxT;

export function LayoutMain({ children, ...rest }: LayoutMainProps) {
  return (
    <Box as="main" height="100%" flex="1 1 auto" {...rest}>
      <ScrollContainer>{children}</ScrollContainer>
    </Box>
  );
}
