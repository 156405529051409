import { MissingStorage, Storage } from './storage';

const initSessionStorage = () => {
  try {
    return Storage(sessionStorage);
  } catch (_ex) {
    return Storage(MissingStorage('sessionStorage'));
  }
};

export const SessionStorage = initSessionStorage();
