import { useState, useEffect } from 'react';
import type { NotificationResponseT } from '@mentimeter/http-clients';
import { core } from '@mentimeter/http-clients';
import {
  useRouter,
  fullMentimeterUrl,
  type MentimeterRouter,
} from '@mentimeter/next-navigation';
import { LocalStorage, SessionStorage } from '@mentimeter/storage';
import {
  useNotification,
  Notifications as NotificationUI,
} from '@mentimeter/__app-split-lib/ui/notifications';
import { networkErrorRequestInterceptor } from 'lib/networkErrorRequestInterceptor';
import type { BoxT } from '@mentimeter/ragnar-ui';
import { trackClickUpgrade } from '@mentimeter/dashboard-tracking';
import { trackUser } from '@api/tracking/client';

const getActionsForNotification = (
  id: string,
  meta: NotificationResponseT['content']['meta'],
  router: MentimeterRouter,
) => {
  switch (id) {
    case 'added-to-group':
      return [
        {
          children: `View shared space`,
          key: `view-shared-space`,
          variant: 'primary' as const,
          onClick: () => {
            router.push(`/app/shared/${meta?.group_id}`);
          },
        },
        {
          children: 'Learn more',
          key: 'learn-more',
          variant: 'secondary' as const,
          href: 'https://help.mentimeter.com/en/articles/4488452-create-groups-within-your-organization',
        },
      ];
    case 'trial-ending':
      return [
        {
          key: 'upgrade-to-pro',
          children: 'Upgrade to Pro',
          href: fullMentimeterUrl('/app/checkout/pro'),
          target: '_blank',
          onClick: () => {
            trackUser({
              event: 'Clicked upgrade to pro button',
              properties: { context: 'Pro trial notification' },
            });
          },
        },
        {
          key: 'see-plans',
          children: 'See plans',
          href: fullMentimeterUrl('/plans'),
          target: '_blank',
          onClick: () =>
            trackClickUpgrade({
              event: 'Clicked see plans button',
              properties: {
                context: 'Pro trial notification',
              },
            }),
        },
      ];
    default:
      return undefined;
  }
};

const getThemeForNotification = (
  type: NotificationResponseT['type'],
  id: string | undefined,
) => {
  if (type === 'success') {
    return 'positive';
  }
  if (type === 'warning') {
    return 'notice';
  }
  if (id === 'added-to-group') {
    return 'info';
  }
  return undefined;
};

const Notifications = (props: BoxT) => {
  const { items, displayNotification } = useNotification();
  const router = useRouter();

  const [fetched, setFetched] = useState(false);

  useEffect(() => {
    if (fetched) {
      return;
    }
    setFetched(true);
    core()
      .users.getUnreadNotifications()
      .then(({ data }) => {
        const description = data.content.message;
        const { title, meta, id } = data.content;
        const storage = meta?.is_session_notification
          ? SessionStorage
          : LocalStorage;

        const trimAndLowerCase = (str = '') =>
          str.replace(/\s/g, '').toLocaleLowerCase();
        // to be sure we dont hide unseen notifcations
        const seenNotificationId = [
          'notification',
          trimAndLowerCase(id),
          trimAndLowerCase(JSON.stringify(meta)),
          trimAndLowerCase(description),
        ].join('-');
        const hasSeenNotification =
          storage.getItem(seenNotificationId) !== null;
        if (!hasSeenNotification) {
          displayNotification({
            description,
            title,
            id: description,
            theme: getThemeForNotification(data.type, id),
            // @ts-expect-error-auto TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
            actions: getActionsForNotification(id, meta, router),
            onDismiss: () => {
              storage.setItem({
                type: 'functional',
                key: seenNotificationId,
                value: '1',
              });
            },
          });
        }
      })
      .catch(() => {
        // Error handled globally
      });
  }, [displayNotification, fetched, router]);

  networkErrorRequestInterceptor(({ status }) => {
    switch (status) {
      case 401:
        displayNotification({
          description: 'Seems like you are not allowed to do that.',
          title: 'Unauthorized',
          theme: 'negative',
          id: status.toString(),
        });
        break;
      case 'ECONNABORTED':
        displayNotification({
          description:
            'Page took too long to load. This could be because of a slow internet connection.',
          title: 'Error',
          theme: 'negative',
          id: 'ECONNABORTED',
        });
        break;
      case 'Network Error':
        displayNotification({
          description: 'Could not reach server',
          title: 'Error',
          theme: 'negative',
          id: 'Network Error',
        });
        break;
      case 400:
        // handle this in your component
        break;
      default:
        displayNotification({
          description:
            'Reload the page and contact support if the error persists.',
          title: 'Something went wrong',
          actions: [
            {
              children: 'Reload',
              key: 'reload',
              onClick: () => {
                window.location.reload();
              },
            },
          ],
          theme: 'negative',
          id: status !== undefined ? status.toString() : 'Unknown error',
        });
        break;
    }
  }, core());

  return <NotificationUI items={items} {...props} />;
};

export default Notifications;
