'use client';

import { DashboardEngagementLimitBanner } from '@mentimeter/paywalls/engagement-limit';
import {
  LayoutHeader,
  LayoutMain,
  LayoutRoot,
  LayoutApp,
  LayoutBanners,
  LayoutSidebar,
} from '@mentimeter/dashboard-ui';
import {
  Desktop,
  Mobile,
} from 'app/(authenticated)/(main)/components/responsive-helpers';
import { SettingsHeader } from '../components/header/SettingsHeader';
import MobileMenu from '../components/mobile-menu/mobile-menu';
import Notifications from '../components/notifications';
import { SettingsMenu } from '../components/side-menu/settings-menu/SettingsMenu';

export const SettingsLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <LayoutRoot>
      <LayoutBanners>
        <DashboardEngagementLimitBanner />
      </LayoutBanners>

      <LayoutApp>
        <Mobile>
          <MobileMenu />
        </Mobile>
        <Desktop>
          <LayoutSidebar>
            <SettingsMenu />
          </LayoutSidebar>
        </Desktop>
        <LayoutMain>
          <LayoutHeader>
            <Notifications />
            <SettingsHeader />
          </LayoutHeader>

          {children}
        </LayoutMain>
      </LayoutApp>
    </LayoutRoot>
  );
};
