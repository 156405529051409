import React from 'react';
import { Box, type BoxT } from '@mentimeter/ragnar-ui';

type LayoutRootProps = BoxT;

export function LayoutRoot({ children, ...rest }: LayoutRootProps) {
  return (
    <Box bg="bg" height="100%" width="100%" {...rest}>
      {children}
    </Box>
  );
}
