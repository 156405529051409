import type { Rule } from '@mentimeter/ragnar-styled';
import { addUnit, px } from '@mentimeter/ragnar-utils';
import { getColors } from './utils';
import type { ButtonT } from './types';

export const rule: Rule<ButtonT> = ({
  theme,
  size = 'default',
  variant = 'tertiary',
  disabled,
  state,
}) => {
  const buttonColors = getColors({
    theme,
    state,
    variant,
  });

  const buttonSizes = {
    large: {
      paddingTop: px(theme.kosmosSpacing.space4),
      paddingBottom: px(theme.kosmosSpacing.space4),
      paddingLeft: px(theme.kosmosSpacing.space8),
      paddingRight: px(theme.kosmosSpacing.space8),
    },
    default: {
      paddingTop: px(theme.kosmosSpacing.space3),
      paddingBottom: px(theme.kosmosSpacing.space3),
      paddingLeft: px(theme.kosmosSpacing.space6),
      paddingRight: px(theme.kosmosSpacing.space6),
    },
    compact: {
      paddingTop: px(theme.kosmosSpacing.space2),
      paddingBottom: px(theme.kosmosSpacing.space2),
      paddingLeft: px(theme.kosmosSpacing.space4),
      paddingRight: px(theme.kosmosSpacing.space4),
    },
  };

  const fontSizes = {
    large: {
      fontSize: addUnit(theme.kosmosFontSize[100]),
      lineHeight: addUnit(theme.kosmosLineHeight.fixed[125]),
    },
    default: {
      fontSize: addUnit(theme.kosmosFontSize['87.5']),
      lineHeight: addUnit(theme.kosmosLineHeight.fixed[100]),
    },
    compact: {
      fontSize: addUnit(theme.kosmosFontSize['87.5']),
      lineHeight: addUnit(theme.kosmosLineHeight.fixed[100]),
    },
  };

  const generalStyling = {
    position: 'relative',
    whiteSpace: 'nowrap',
    textDecoration: 'none',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    opacity: 1,
    borderRadius:
      variant === 'subtle' ? 'none' : `${theme.kosmosBorderRadius.full}px`,
    fontFamily: theme.fonts.body,
    fontWeight: theme.fontWeights.semiBold,
    transition: `${theme.durations[1]}s ease`,
    ...(variant === 'subtle' && {
      padding: addUnit(theme.kosmosSpacing['space0.5']),
    }),
    ...(variant !== 'subtle' && {
      ...(buttonSizes[size as keyof typeof buttonSizes] || buttonSizes.default),
    }),
    ...(fontSizes[size as keyof typeof fontSizes] || fontSizes.default),
  };

  if (disabled) {
    return {
      ...generalStyling,
      boxShadow: `inset 0px 0px 0px 2px ${
        variant === 'subtle' ? 'transparent' : theme.colors.disabledStrong
      }`,
      color:
        variant === 'subtle'
          ? theme.colors.textDisabled
          : theme.colors.onDisabledStrong,
      backgroundColor:
        variant === 'subtle' ? 'transparent' : theme.colors.disabledStrong,
      cursor: 'not-allowed',
      '@media (hover: hover)': {
        ':hover': {
          boxShadow: `inset 0px 0px 0px 2px ${
            variant === 'subtle' ? 'transparent' : theme.colors.disabledStrong
          }`,
        },
      },
    };
  }

  return {
    ...generalStyling,
    boxShadow: buttonColors?.borderColor
      ? `inset 0px 0px 0px 2px ${buttonColors?.borderColor}`
      : `inset 0px 0px 0px 2px ${buttonColors?.backgroundColor}`,
    color: buttonColors?.color,
    backgroundColor: buttonColors?.backgroundColor,

    cursor: 'pointer',
    ...(!disabled && {
      '@media (hover: hover)': {
        ':hover': {
          color: buttonColors?.hoverColor,
          backgroundColor: buttonColors?.hoverBackgroundColor,
          boxShadow: buttonColors?.hoverBorderColor
            ? `inset 0px 0px 0px 2px ${buttonColors?.hoverBorderColor}`
            : `inset 0px 0px 0px 2px ${buttonColors?.hoverBackgroundColor}`,
        },
        ':active': {
          color: buttonColors?.activeColor,
          backgroundColor: buttonColors?.activeBackgroundColor,
          boxShadow: buttonColors?.activeBorderColor
            ? `inset 0px 0px 0px 2px ${buttonColors?.activeBorderColor}`
            : `inset 0px 0px 0px 2px ${buttonColors?.activeBackgroundColor}`,
        },
      },
    }),
    ':active': {
      color: buttonColors?.activeColor,
      backgroundColor: buttonColors?.activeBackgroundColor,
      boxShadow: buttonColors?.activeBorderColor
        ? `inset 0px 0px 0px 2px ${buttonColors?.activeBorderColor}`
        : `inset 0px 0px 0px 2px ${buttonColors?.activeBackgroundColor}`,
    },

    ':focus-visible': {
      outline: `4px solid ${theme.colors.interactiveFocused}`,
      outlineOffset: '2px',
      // ensures that the outline is always on top of surrounding elements
      zIndex: 1,
    },
  };
};
