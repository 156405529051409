// This file is automatically generated. DO NOT EDIT.

import { type InviteRequests } from '../../types/response/InviteRequests';
import { type UpgradeRequests } from '../../types/response/UpgradeRequests';
import { fromSnakeKeys } from '@api/internal';

export interface GetRequestsResponse {
  /** The invite requests of the workspace */
  inviteRequests: InviteRequests;
  /** The role upgrade requests of the workspace */
  upgradeRequests: UpgradeRequests;
  /** The total count of all requests */
  totalCount: number;
}

export async function getRequestsResponse(
  response: Response,
): Promise<GetRequestsResponse> {
  const json = await response.json();
  return fromSnakeKeys(json) as GetRequestsResponse;
}
