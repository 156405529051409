import React, { forwardRef } from 'react';
import type { LinkProps } from 'next/link';
import NextLink from 'next/link';
import type { ButtonT as RagnarButtonT } from '@mentimeter/ragnar-ui';
import {
  Button as RagnarButton,
  createUnstyledButton,
  styledButtonRule,
} from '@mentimeter/ragnar-ui';
import { styled } from '@mentimeter/ragnar-styled';
import { getRouteInternally } from './getRouteInternally';

export interface LinkButtonT
  extends Omit<RagnarButtonT, 'href'>,
    Pick<LinkProps, 'href'> {}

export const LinkButton = forwardRef<HTMLAnchorElement, LinkButtonT>(
  ({ as, children, href, ...rest }, ref) => {
    const { shouldRouteInternally, internalLink } = getRouteInternally(href);

    if (shouldRouteInternally) {
      return (
        <StyledLinkButton {...rest} href={internalLink} ref={ref}>
          {children}
        </StyledLinkButton>
      );
    }

    return (
      <RagnarButton {...rest} href={href?.toString()} ref={ref}>
        {children}
      </RagnarButton>
    );
  },
);

const _LinkButton = createUnstyledButton(NextLink);

const StyledLinkButton = styled(_LinkButton, {
  displayName: 'Button',
})(styledButtonRule, 'kosmosSpacing', 'width');
