import { type BoxT, Box } from '@mentimeter/ragnar-ui';
import React from 'react';

export function LayoutHeader({ children, ...rest }: BoxT) {
  return (
    <Box
      as="header"
      pl={['space3', 'space6', 'space6', 'space14']}
      pr={['space3', 'space6']}
      py={['space2', 'space4', 'space6']}
      width="100%"
      {...rest}
    >
      {children}
    </Box>
  );
}
