import type { DSC } from '@mentimeter/ragnar-dsc';
import type { ButtonColorsT, RagnarButtonState, VariantT } from './types';
import { ERROR_STATE, SUCCESS_STATE } from './constants';

export const getColors = ({
  theme,
  state,
  variant,
}: {
  theme: DSC;
  state: RagnarButtonState | undefined;
  variant: VariantT;
}) => {
  const VARIANT_MAP: Record<VariantT, ButtonColorsT> = {
    primary: {
      color: theme.colors.onPrimary,
      backgroundColor: theme.colors.buttonPrimaryBg,
      hoverColor: theme.colors.onPrimary,
      hoverBackgroundColor: theme.colors.buttonPrimaryBgHover,
      activeColor: theme.colors.onPrimary,
      activeBackgroundColor: theme.colors.buttonPrimaryBgActive,
    },
    secondary: {
      color: theme.colors.text,
      backgroundColor: 'transparent',
      hoverColor: theme.colors.text,
      hoverBackgroundColor: theme.colors.buttonSecondaryBgHover,
      activeColor: theme.colors.text,
      activeBackgroundColor: theme.colors.buttonSecondaryBgActive,
    },
    tertiary: {
      color: theme.colors.text,
      backgroundColor: theme.colors.buttonTertiaryBg,
      hoverColor: theme.colors.text,
      hoverBackgroundColor: theme.colors.buttonTertiaryBgHover,
      activeColor: theme.colors.text,
      activeBackgroundColor: theme.colors.buttonTertiaryBgActive,
    },
    brand: {
      color: theme.colors.onBrand,
      backgroundColor: theme.colors.buttonBrandBg,
      hoverColor: theme.colors.onBrand,
      hoverBackgroundColor: theme.colors.buttonBrandBgHover,
      activeColor: theme.colors.onBrand,
      activeBackgroundColor: theme.colors.buttonBrandBgActive,
    },
    outline: {
      color: theme.colors.text,
      backgroundColor: 'transparent',
      borderColor: theme.colors.primary,
      hoverColor: theme.colors.text,
      hoverBackgroundColor: theme.colors.buttonOutlineBgHover,
      hoverBorderColor: theme.colors.primary,
      activeColor: theme.colors.text,
      activeBackgroundColor: theme.colors.buttonOutlineBgActive,
      activeBorderColor: theme.colors.primary,
    },
    negative: {
      color: theme.colors.onNegative,
      backgroundColor: theme.colors.buttonNegativeBg,
      hoverColor: theme.colors.onNegative,
      hoverBackgroundColor: theme.colors.buttonNegativeBgHover,
      activeColor: theme.colors.onNegative,
      activeBackgroundColor: theme.colors.buttonNegativeBgActive,
    },
    negativeWeak: {
      color: theme.colors.onNegativeWeakest,
      backgroundColor: theme.colors.buttonNegativeWeakBg,
      hoverColor: theme.colors.onNegativeWeakest,
      hoverBackgroundColor: theme.colors.buttonNegativeWeakBgHover,
      activeColor: theme.colors.onNegativeWeakest,
      activeBackgroundColor: theme.colors.buttonNegativeWeakBgActive,
    },
    positive: {
      color: theme.colors.onPositive,
      backgroundColor: theme.colors.buttonPositiveBg,
      hoverColor: theme.colors.onPositive,
      hoverBackgroundColor: theme.colors.buttonPositiveBgHover,
      activeColor: theme.colors.onPositive,
      activeBackgroundColor: theme.colors.buttonPositiveBgActive,
    },
    subtle: {
      color: theme.colors.text,
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      hoverColor: theme.colors.text,
      hoverBackgroundColor: 'transparent',
      hoverBorderColor: 'transparent',
      activeColor: theme.colors.text,
      activeBackgroundColor: 'transparent',
      activeBorderColor: 'transparent',
    },
  };

  return VARIANT_MAP[
    state === SUCCESS_STATE
      ? 'positive'
      : state === ERROR_STATE
        ? 'negative'
        : variant
  ];
};
