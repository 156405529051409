import { useState } from 'react';
import { Box, CalloutCard } from '@mentimeter/ragnar-ui';
import type { CalloutCardT as NotifyT, BoxT } from '@mentimeter/ragnar-ui';
import { v4 as uuid } from 'uuid';

type DisplayNotificationT = NotifyT & {
  id?: string;
  onDismiss?: () => void;
};

type NotificationT = DisplayNotificationT & {
  onDismiss: () => void;
};

type ItemT = NotificationT & {
  id: string;
};

export function useNotification() {
  const [items, setItems] = useState<Array<ItemT>>([]);

  function removeNotification(id: string) {
    setItems((prev) => prev.filter((x) => x.id !== id));
  }

  function resetNotifications() {
    items.map((d) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      d.onDismiss && d.onDismiss();
      removeNotification(d.id);
    });
  }

  function displayNotification({
    onDismiss,
    id,
    ...rest
  }: DisplayNotificationT) {
    const uId = id || uuid();
    setItems((prev) =>
      !items.map((d) => d.id).includes(uId)
        ? [
            ...prev,
            {
              id: uId,
              ...rest,
              onDismiss: () => {
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                onDismiss && onDismiss();
                removeNotification(uId);
              },
            },
          ]
        : prev,
    );
    return () => {
      removeNotification(uId);
    };
  }
  return { items, displayNotification, removeNotification, resetNotifications };
}

export const Notifications = ({
  items,
  mb = '4',
  mt,
}: {
  items: Array<ItemT>;
} & BoxT) => {
  const ids = items.map(({ id }) => id);

  const uniqueIds = [...Array.from(new Set(ids))];

  if (items.length <= 0) return null;

  return (
    <Box mb={mb} width="100%" mt={mt}>
      {uniqueIds.map((id) => {
        const item = items.find((d) => d.id === id);

        return (
          item && (
            <CalloutCard mb={[2]} {...item} key={item.id} enableMarkdown />
          )
        );
      })}
    </Box>
  );
};
