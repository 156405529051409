'use client';

import * as React from 'react';
import { LoaderIcon } from '@mentimeter/ragnar-visuals';
import { useHasMounted } from '@mentimeter/react-hooks';
import { Box } from '@mentimeter/ragnar-ui';
import styles from './RenderOnClient.module.css';

interface RenderOnClientProps {
  children: React.ReactNode;
  loader?: boolean;
}

// This component forces the children to render only on the client side.
// To get HTML return from server-side this has to be removed, you should
// work on removing this wrapper from your page.
// SVG animations do not load until the rest of the page is loaded.
// We add css animation instead.
export function RenderOnClient({
  children,
  loader = true,
}: RenderOnClientProps) {
  const hasMounted = useHasMounted();

  if (!hasMounted && loader)
    return (
      <Box
        alignItems="center"
        justifyContent="center"
        position="absolute"
        width="100%"
        height="100%"
      >
        <div className={styles.loader}>
          <LoaderIcon size={5} />
        </div>
      </Box>
    );

  if (!hasMounted) return null;

  return children;
}
