import type { AxiosError, AxiosRequestConfig } from 'axios';
import type { ClientBaseT } from '@mentimeter/http-clients';

type ErrorConfig = AxiosError<any> & {
  status?: number;
  config: AxiosRequestConfig & {
    handleErrorGlobally: boolean;
  };
};

export const networkErrorRequestInterceptor = (
  handler: (arg0: {
    description: string;
    title: string;
    status?: number | 'ECONNABORTED' | 'Network Error';
  }) => void | (() => void),
  client: ClientBaseT<any>,
) => {
  client.interceptors.response.use(
    // @ts-expect-error-auto TS(7006) FIXME: Parameter 'config' implicitly has an 'any' type.
    (config) => config,
    (error: ErrorConfig) => {
      const gotResponse = Boolean(error.response);
      const handleError = Boolean(
        error.config && error.config.handleErrorGlobally,
      );
      const e = gotResponse
        ? error.response
        : JSON.parse(JSON.stringify(error));
      if (gotResponse && handleError) {
        handler({
          description:
            'Reload the page and contact support if the error persists.',
          title: 'Something went wrong',

          status: e.status,
        });
      } else if (handleError) {
        if (e.code === 'ECONNABORTED') {
          handler({
            description:
              'Page took too long to load. This could be because of a slow internet connection.',
            title: 'Error',
            status: e.code,
          });
        }

        if (e.message === 'Network Error') {
          handler({
            description: 'Could not reach server',
            title: 'Error',
            status: e.message,
          });
        }
      }
      return Promise.reject(error);
    },
  );
};
