import React from 'react';
import { Box, type BoxT } from '@mentimeter/ragnar-ui';

type LayoutBannersProps = BoxT;

export function LayoutBanners({ children, ...rest }: LayoutBannersProps) {
  return (
    <Box width="100%" flexDirection="row" {...rest}>
      {children}
    </Box>
  );
}
