import React from 'react';
import { Box, type BoxT } from '@mentimeter/ragnar-ui';

type LayoutAppProps = BoxT;

export function LayoutApp({ children, ...rest }: LayoutAppProps) {
  return (
    <Box width="100%" flexDirection="row" flex="1 1 auto" {...rest}>
      {children}
    </Box>
  );
}
