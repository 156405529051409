import React from 'react';
import { Box, type BoxT } from '@mentimeter/ragnar-ui';
import { ScrollContainer } from './components';

export function LayoutSidebar({ children, ...rest }: BoxT) {
  return (
    <Box as="aside" width={['240px', '240px', '280px']} height="100%" {...rest}>
      <ScrollContainer>
        <Box px="space8" pt="space6" pb="space12" width="100%" flex="1 0 auto">
          {children}
        </Box>
      </ScrollContainer>
    </Box>
  );
}
