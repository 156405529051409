'use client';

import React, { useState } from 'react';
import type { BoxT, ClickableT } from '@mentimeter/ragnar-ui';
import {
  Box,
  Button,
  Clickable,
  Text,
  DrawerRight,
  ModalRoot,
  Collapsable,
  IconButton,
  Avatar,
} from '@mentimeter/ragnar-ui';
import {
  ChevronDownIcon,
  CrossIcon,
  HomeIcon,
  AdminIcon,
  InboxIcon,
  SettingsIcon,
  UserIcon,
  UsersIcon,
  TemplatePlusIcon,
  type FunctionalIconT,
  SendIcon,
} from '@mentimeter/ragnar-visuals';
import { Device } from '@mentimeter/ragnar-device';
import { LinkClickable, usePathname } from '@mentimeter/next-navigation';
import { useDevelopmentToolsModal } from '@mentimeter/development-tools/hooks';
import { logout } from 'lib/legacy-actions';
import { getIsMemberLite, useUser, getNameInitials } from '@mentimeter/user';
import { useMobileMenu } from '@mentimeter/__app-split-features/shell/components/mobile-menu/MobileMenuProvider';
import { useFeatures } from '@mentimeter/workspace-features';
import { TemplateModal } from '@mentimeter/templates-modal/TemplateModal/TemplateModal';
import { useWorkspacePresentationsFeatureTitle } from '@mentimeter/workspace';
import { useWorkspace } from '@mentimeter/workspace-data-hooks';
import { useSplits } from '@mentimeter/splitio';
import { billingRules, useSubscriptions } from '@mentimeter/billing';
import { HelpModal } from '../header/user-actions/components/HelpModal';
import { REQUESTS } from '../header/user-actions/components/user-menu/userMenuItems';

export const WORKSPACE_SECTION_TITLE = 'Workspace';

/**
 * Menu components
 */

const MenuDrawer = ({
  children,
  show,
  onDismiss,
}: {
  children: React.ReactNode;
  show: boolean;
  onDismiss: () => void;
} & BoxT) => (
  <DrawerRight zIndex={9999} show={show} sticky>
    <Box
      width="100vw"
      height="100%"
      bg="bg"
      position="relative"
      pt="space6"
      px="space6"
      pb="space20"
      overflow="auto"
    >
      <Box width="100%" alignItems="flex-end">
        <IconButton
          variant="secondary"
          aria-label="Close menu"
          onClick={onDismiss}
          size="large"
        >
          <CrossIcon />
        </IconButton>
      </Box>
      <Box width="100%" gap="space12">
        {children}
      </Box>
    </Box>
  </DrawerRight>
);

const MenuSection = ({ children, ...rest }: BoxT) => (
  <Box width="100%" {...rest}>
    {children}
  </Box>
);

const MenuSectionHeading = ({ children }: { children: string }) => (
  <Box width="100%" px="space4" pt="space2" pb="space1">
    <Text fontSize="87.5" color="textWeaker">
      {children}
    </Text>
  </Box>
);

const MenuItem = ({
  children,
  active,
  ...rest
}: ClickableT & { active?: boolean }) => (
  <Clickable
    width="100%"
    borderRadius="xl"
    extend={({ theme }) => ({
      '@media (hover: hover)': {
        ':hover': {
          opacity: 1,
          backgroundColor: theme.colors.infoWeakest,
        },
      },
      ':active': {
        opacity: 1,
        backgroundColor: theme.colors.infoWeak,
      },
    })}
    {...rest}
  >
    {active && (
      <Box
        width="2px"
        bg="secondary"
        height="45%"
        position="absolute"
        top="50%"
        left={0}
        extend={() => ({
          transform: 'translateY(-50%)',
        })}
      />
    )}
    {children}
  </Clickable>
);

const MenuItemContent = ({
  children,
  compact = false,
  icon: Icon,
  ...props
}: BoxT & {
  compact?: boolean;
  icon?: React.ComponentType<FunctionalIconT> | undefined;
}) => (
  <Box
    alignItems="center"
    px="space4"
    py={compact ? 'space2' : 'space3'}
    flexDirection="row"
    gap="space3"
    width="100%"
    borderRadius="xl"
    {...props}
  >
    {Icon && (
      <Box>
        <Icon size={4} />
      </Box>
    )}
    <Text
      truncate
      lineHeight={compact ? 'normal' : 'snug'}
      fontSize={compact ? '100' : '125'}
      color="text"
    >
      {children}
    </Text>
  </Box>
);

const MenuItemNextNavigation = ({
  href,
  children,
  target = undefined,
  compact = false,
  icon,
  ...props
}: {
  href: string;
  children: React.ReactNode;
  target?: '_blank' | undefined;
  compact?: boolean;
  icon?: React.ComponentType<FunctionalIconT>;
}) => {
  const highlight = usePathname() === href;

  return (
    <MenuItem tabIndex={-1} active={highlight}>
      <LinkClickable
        {...props}
        href={href}
        target={target}
        width="100%"
        aria-label={`Navigate to ${children}`}
        extend={() => ({
          '@media (hover: hover)': {
            ':hover': {
              opacity: 1,
            },
          },
          ':active': {
            opacity: 1,
          },
        })}
      >
        <MenuItemContent icon={icon} compact={compact}>
          {children}
        </MenuItemContent>
      </LinkClickable>
    </MenuItem>
  );
};

const MenuCollapsable = ({
  children,
  title,
  'aria-label': ariaLabel,
  ...props
}: ClickableT) => {
  const [show, setShow] = useState(false);

  return (
    <>
      <MenuItem
        {...props}
        aria-label={ariaLabel || 'Open drop down'}
        onClick={() => setShow((prev) => !prev)}
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <MenuItemContent width="auto" icon={SettingsIcon}>
          {title}
        </MenuItemContent>

        <Box
          mr="space4"
          extend={({ theme }) => ({
            transition: `transform ${theme.durations[1]}s ease`,
            transform: show ? 'rotate(180deg)' : 'rotate(0)',
          })}
        >
          <ChevronDownIcon size={4} />
        </Box>
      </MenuItem>

      <Collapsable width="100%" show={show} pl="space10">
        {children}
      </Collapsable>
    </>
  );
};

/**
 * All menu items
 */

const Home = () => (
  <MenuItemNextNavigation href="/app/home" icon={HomeIcon}>
    Home
  </MenuItemNextNavigation>
);

const MyPresentations = () => (
  <MenuItemNextNavigation icon={UserIcon} href="/app/dashboard">
    My presentations
  </MenuItemNextNavigation>
);

const FindAvailableTeams = () => {
  const { hasFindAvailableTeamsFeature } = useFeatures();
  if (!hasFindAvailableTeamsFeature) return null;

  return (
    <MenuItemNextNavigation icon={SendIcon} href="/app/find-your-team">
      Find available teams
    </MenuItemNextNavigation>
  );
};

const SharedWithMe = () => (
  <MenuItemNextNavigation icon={InboxIcon} href="/app/shared-with-me">
    Shared with me
  </MenuItemNextNavigation>
);

const Billing = () => (
  <MenuItemNextNavigation compact href="/app/billing">
    Billing
  </MenuItemNextNavigation>
);

const ManageMembers = () => (
  <MenuItemNextNavigation compact href="/app/manage-members">
    Manage members
  </MenuItemNextNavigation>
);

const WorkspaceSettings = () => {
  const { hasWorkspaceSettingsFeature } = useFeatures();
  if (!hasWorkspaceSettingsFeature) return null;

  return (
    <MenuItemNextNavigation compact href="/app/workspace-settings">
      Workspace settings
    </MenuItemNextNavigation>
  );
};

const Groups = () => {
  const { hasGroupsFeature } = useFeatures();
  if (!hasGroupsFeature) return null;

  return (
    <MenuItemNextNavigation compact href="/app/groups">
      Groups
    </MenuItemNextNavigation>
  );
};

const WorkspaceInsights = () => {
  const { hasWorkspaceInsightsFeature } = useFeatures();
  if (!hasWorkspaceInsightsFeature) return null;

  return (
    <MenuItemNextNavigation compact href="/app/workspace-insights">
      Workspace insights
    </MenuItemNextNavigation>
  );
};

function Requests() {
  const { Mango_Request_Page_V2 } = useSplits(['Mango_Request_Page_V2']);
  const { isOwnerOrAdmin, isFreeUser } = useFeatures();
  const isPaidUser = !isFreeUser;

  if (Mango_Request_Page_V2 === 'on' && isPaidUser && isOwnerOrAdmin) {
    return (
      <MenuItemNextNavigation compact href={REQUESTS.href}>
        {REQUESTS.key}
      </MenuItemNextNavigation>
    );
  }

  return null;
}

const MyProfile = () => (
  <MenuItemNextNavigation icon={AdminIcon} href="/app/settings">
    My profile
  </MenuItemNextNavigation>
);

const Workspace = ({ children }: { children: string }) => (
  <MenuItemNextNavigation icon={UsersIcon} href="/app/workspace">
    {children}
  </MenuItemNextNavigation>
);

const SharedTemplates = () => (
  <MenuItemNextNavigation icon={TemplatePlusIcon} href="/app/shared">
    Shared templates
  </MenuItemNextNavigation>
);

const Plans = () => (
  <MenuItemNextNavigation compact href="/plans">
    Plans
  </MenuItemNextNavigation>
);

const Help = ({ onClick }: { onClick: () => void }) => (
  <MenuItem onClick={onClick}>
    <MenuItemContent compact>Help</MenuItemContent>
  </MenuItem>
);

const Templates = ({ onClick }: { onClick: () => void }) => (
  <MenuItem onClick={onClick}>
    <MenuItemContent compact>Templates</MenuItemContent>
  </MenuItem>
);

const MentiAcademy = () => (
  <MenuItemNextNavigation
    href="https://academy.mentimeter.com/"
    target="_blank"
    compact
  >
    Menti Academy
  </MenuItemNextNavigation>
);

const Trash = () => (
  <MenuItemNextNavigation compact href="/app/trash">
    Trash
  </MenuItemNextNavigation>
);

/**
 * Full menu
 */

const MobileMenu = () => {
  const { user } = useUser();
  const { subscriptions } = useSubscriptions(!user);
  const { isTrialing, hasCurrentSubscription } = billingRules(subscriptions);
  const {
    hideWorkspacePresentationPage,
    hasSharedTemplatesFeature,
    isLoadingFeatures,
  } = useFeatures();

  const isSharedTemplatesAllowed =
    !getIsMemberLite(user) && hasSharedTemplatesFeature;

  const { tryOpenDevelopmentToolsModal } = useDevelopmentToolsModal();
  const mobileMenu = useMobileMenu();
  const [showHelp, setShowHelp] = useState(false);
  const [showTemplates, setShowTemplates] = useState(false);
  const { workspacePresentationsFeatureTitle, isLoading: isLoadingTitle } =
    useWorkspacePresentationsFeatureTitle();
  const { data: workspace } = useWorkspace();

  if (isLoadingFeatures || !user || isLoadingTitle) return null;

  const canUpgrade = !hasCurrentSubscription || isTrialing;

  const handleShowTemplates = () => {
    setShowTemplates(true);
    mobileMenu.close();
  };

  const handleShowHelp = () => {
    setShowHelp(true);
    mobileMenu.close();
  };

  return (
    <Box
      extend={() => ({
        '@media print': {
          display: 'none',
        },
      })}
    >
      <Device.Match lessThan={3}>
        <MenuDrawer show={mobileMenu.isOpen} onDismiss={mobileMenu.close}>
          {/* Top section */}
          <MenuSection>
            <Home />
            <MyPresentations />
            <FindAvailableTeams />
            <SharedWithMe />
            <MenuCollapsable title="My workspace">
              <Billing />
              <WorkspaceSettings />
              <ManageMembers />
              <Requests />
              <Groups />
              <WorkspaceInsights />
            </MenuCollapsable>
            <MyProfile />
          </MenuSection>

          {/* Shared section */}
          <MenuSection>
            <MenuSectionHeading>
              {workspace?.name ?? WORKSPACE_SECTION_TITLE}
            </MenuSectionHeading>
            {!hideWorkspacePresentationPage && (
              <Workspace>{workspacePresentationsFeatureTitle}</Workspace>
            )}
            {isSharedTemplatesAllowed && <SharedTemplates />}
          </MenuSection>

          {/* MISC section */}
          <MenuSection>
            {canUpgrade && <Plans />}
            <Help onClick={handleShowHelp} />
            <Templates onClick={handleShowTemplates} />
            <MentiAcademy />
            <Trash />
          </MenuSection>

          {/* Profile and logout */}
          <MenuSection gap="space6" alignItems="center">
            <Box alignItems="center" width="100%">
              <Avatar
                mb="space2"
                itemId={String(user.id)}
                name={user.name}
                initials={getNameInitials(user.name).toUpperCase()}
                type="user"
                color="black"
                profilePictureUrl={user.profile_picture?.presets?.preview?.url}
              />

              <Text truncate variant="ragnarBodyLg" color="text">
                {user.name}
              </Text>
              <Text
                variant="ragnarBodySm"
                color="textWeaker"
                truncate
                onClick={() => {
                  if (tryOpenDevelopmentToolsModal()) {
                    mobileMenu.close();
                  }
                }}
              >
                {user.email}
              </Text>
            </Box>
            <Button onClick={logout} variant="outline" size="large">
              Log out
            </Button>
          </MenuSection>
        </MenuDrawer>

        <ModalRoot open={showHelp} onOpenChange={setShowHelp}>
          <HelpModal open={showHelp} />
        </ModalRoot>

        <ModalRoot open={showTemplates} onOpenChange={setShowTemplates}>
          <TemplateModal
            open={showTemplates}
            trackingContext="Dashboard mobile menu"
            modalOpeningContext="homeview"
            onDismiss={() => setShowTemplates(false)}
          />
        </ModalRoot>
      </Device.Match>
    </Box>
  );
};

export default MobileMenu;
