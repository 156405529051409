// This file is automatically generated. DO NOT EDIT.

import { getCoreURL } from '@api/internal';

export interface GetRequestsRequest {
  region: 'eu' | 'us';
  userAuth: string;
}

export function getRequestsRequest(requestParams: GetRequestsRequest): Request {
  const coreURL = getCoreURL(requestParams.region);
  const url = `${coreURL}/workspaces/requests`;
  return new Request(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${requestParams.userAuth}`,
    },
  });
}
