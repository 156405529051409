import { userCache } from '@mentimeter/user';
import {
  getRequestsRequest,
  getRequestsResponse,
  type GetRequestsResponse,
} from '@core-api/workspace-requests/workspaces/requests';
import { captureException, MentiError } from '@mentimeter/errors';
import useSWR, { mutate } from 'swr';
import { deleteRequestsRequest } from '@core-api/workspace-requests/workspaces/requests/{workspace_request_id}';

const WORKSPACE_REQUESTS_SWR_CACHE_KEY = 'workspace-requests';

export const useWorkspaceRequests = () => {
  const mutateRequests = () => {
    mutate(WORKSPACE_REQUESTS_SWR_CACHE_KEY);
  };

  const deleteRequest = async (workspaceRequestId: string) => {
    const region = userCache.region;
    const sessionToken = userCache.getToken();

    const request = deleteRequestsRequest({
      region,
      userAuth: sessionToken,
      workspaceRequestId,
    });

    await fetch(request);
  };
  const { data, error } = useSWR<GetRequestsResponse>(
    WORKSPACE_REQUESTS_SWR_CACHE_KEY,
    async () => {
      const region = userCache.region;
      const sessionToken = userCache.getToken();
      const response = await fetch(
        getRequestsRequest({ region, userAuth: sessionToken }),
      );

      if (!response.ok) {
        const e = new MentiError('Error fetching workspace requests', {
          feature: 'workspace-requests',
        });
        captureException(e);
        return {
          inviteRequests: { totalCount: 0, items: [] },
          upgradeRequests: { totalCount: 0, items: [] },
          totalCount: 0,
        };
      }
      const data = await getRequestsResponse(response);
      return data;
    },
  );

  return {
    mutateRequests,
    deleteRequest,
    data,
    error,
  };
};
